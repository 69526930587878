import React, { ReactNode } from "react";

type Props = {
  imgUrl: string;
  color: string;
  titleStrong: string;
  titleStrongIndent?: boolean;
  titleSmall: string;
  titleSmallIndent?: boolean;
  children: ReactNode;
  notice?: string;
};
const Contact2Item: React.FC<Props> = (props) => {
  return (
    <article
      className={`group ${props.color} rounded-lg overflow-hidden md:grid md:grid-cols-[36%_1fr]`}
    >
      <figure className="block">
        <img
          src={props.imgUrl}
          alt=""
          className="w-full h-full object-cover object-center"
        />
      </figure>
      <div className="relative p-5 text-white group-[.primary]:bg-primary group-[.green]:bg-green md:pt-10 md:pb-[22px] md:px-9">
        <div className="absolute -bottom-12 -right-7 w-[235px]">
          <img src="/top/contact2-bg.webp" width="235" height="292" alt="" />
        </div>
        <div className="relative">
          <h2 className="mb-3 font-black md:mb-5">
            <span
              className={`block mb-1 text-sm leading-normal md:text-lg md:leading-[1.3] ${
                props.titleSmallIndent ? "-indent-2 md:-indent-3" : ""
              }`}
            >
              {props.titleSmall}
            </span>
            <strong
              className={`block text-2xl leading-normal md:text-[30px] md:leading-[1.3] ${
                props.titleStrongIndent ? "-indent-3 md:-indent-4" : ""
              }`}
            >
              {props.titleStrong}
            </strong>
          </h2>
          <div className="text-sm leading-[1.8] font-medium md:leading-normal">
            {props.children}
          </div>
          <p className="mt-2 text-xs sp:text-center">{props.notice}</p>
        </div>
      </div>
    </article>
  );
};

export default Contact2Item;
