import React, { ReactNode } from "react";

export type HideStickyProps = {
  children: ReactNode;
  placement: "top" | "bottom";
  max?: number;
  min?: number;
};
export function HideSticky({
  children,
  placement,
  max = 0,
  min,
}: HideStickyProps) {
  const el = React.useRef<HTMLDivElement>(null);
  const currentOffset = React.useRef(max);
  const currentScrollTop = React.useRef(0);

  React.useEffect(() => {
    const sticky = el.current?.firstElementChild as HTMLElement;
    let { height } = sticky.getBoundingClientRect();
    currentScrollTop.current = document.documentElement.scrollTop;

    // サイズ変更を反映する
    const observer = new ResizeObserver(() => {
      height = sticky.getBoundingClientRect().height;
      if (min == null) {
        handleScroll();
      }
    });
    observer.observe(sticky);

    function handleScroll() {
      const before = currentScrollTop.current;
      currentScrollTop.current = document.documentElement.scrollTop;
      const scroll = before - currentScrollTop.current;

      currentOffset.current = Math.min(
        max,
        Math.max(min ?? -height, currentOffset.current + scroll)
      );

      sticky.style[placement] = `${currentOffset.current}px`;
    }
    window.addEventListener("scroll", handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      observer.disconnect();
    };
  }, [placement, max, min]);
  return (
    <div ref={el} className="contents">
      {children}
    </div>
  );
}
