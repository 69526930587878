import React from "react";
import { useInView } from "react-intersection-observer";
import { HideSticky } from "./HideSticky";
import { useTransition } from "react-transition-state";

type Props = {
  page?: string;
};

const Header: React.FC<Props> = (props) => {
  const { ref, inView } = useInView();

  // メニュー開閉アニメーション用
  const [{ status, isMounted }, transitionToggle] = useTransition({
    timeout: 500,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true,
    initialEntered: false,
  });

  return (
    <>
      <HideSticky placement="top">
        <header
          className={`group sticky top-0 bg-white z-30 ${
            props.page
          } [&.top]:bg-transparent [.top]:transition-[background-color] duration-500 ${
            inView ? "" : "scrolled"
          } [&.top.scrolled]:bg-white`}
        >
          <div className="flex items-center h-[88px] pl-4 md:h-[112px] md:pl-14">
            <div>
              <h1 className="mb-1 text-xs text-tertiary group-[.top]:text-white group-[.scrolled]:!text-tertiary font-bold leading-snug sp:origin-bottom-left sp:scale-75 md:mb-2">飲食店の正社員求人・転職はエフジョブ求人</h1>
              <a href="/" className="block">
                <span className="block w-[158px] md:w-[220px]">
                  <img
                    src="/logo-orange.webp"
                    width="360"
                    height="65"
                    alt="エフジョブ求人"
                    className="group-[.top]:hidden group-[.scrolled]:!block"
                  />
                  <img
                    src="/logo-white.webp"
                    width="360"
                    height="65"
                    alt="エフジョブ求人"
                    className="hidden group-[.top]:block group-[.scrolled]:!hidden"
                  />
                </span>
              </a>
            </div>
          </div>
        </header>
      </HideSticky>
      <div ref={ref} className="absolute top-[500px]"></div>
      <button
        onClick={() => transitionToggle(true)}
        className="fixed top-2 right-2 z-30 md:top-4 md:right-14"
      >
        <span className="flex items-center justify-center rounded-full bg-primary w-[72px] aspect-square md:w-20">
          <span className="block">
            <svg className="block w-7 h-7 mx-auto fill-white">
              <use xlinkHref="/symbol-defs.svg#icon-menu"></use>
            </svg>
            <span className="block text-white text-[10px] font-bold tracking-none leading-none">
              メニュー
            </span>
          </span>
        </span>
      </button>
      {isMounted && (
        <div
          className={`fixed w-full z-40 inset-0 opacity-100 transition-opacity duration-500 ${status} [&.preEnter]:opacity-0 [&.exiting]:opacity-0`}
        >
          <div className="absolute inset-0 overflow-auto md:grid md:grid-cols-[1fr_518px]">
            <div
              className="sp:hidden"
              onClick={() => transitionToggle(false)}
              onKeyDown={() => transitionToggle(false)}
              role="button"
              tabIndex={0}
            ></div>
            <div className="bg-white pt-[88px] sp:min-h-full md:pt-[128px]">
              <nav className="py-8 sp:max-w-[280px] sp:mx-auto md:px-14">
                <ul className="text-sm leading-normal font-bold md:text-base md:leading-normal [&_li]:bg-[linear-gradient(to_right,#F08300_2px,transparent_2px)] [&_li]:bg-[length:8px_2px] [&_li]:bg-repeat-x [&_li]:bg-left-bottom [&_a]:flex [&_a]:items-center [&_a]:py-4 [&_a]:pr-2 [&_span]:flex-1 [&_svg]:flex-shrink-0 [&_svg]:block [&_svg]:aspect-square [&_svg]:ml-2 [&_svg]:fill-default [&_a:hover]:text-primary [&_a:hover_svg]:fill-primary md:[&_a]:py-[18px]">
                  <li>
                    <a href="/" onClick={() => transitionToggle(false)}>
                      <span>TOP</span>
                      <svg className="w-2.5 md:w-[13px] md:mr-1">
                        <use xlinkHref="/symbol-defs.svg#icon-chevron-right"></use>
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      href="/#sect-about"
                      onClick={() => transitionToggle(false)}
                    >
                      <span>エフジョブ求人とは</span>
                      <svg className="w-2.5 md:w-[13px] md:mr-1">
                        <use xlinkHref="/symbol-defs.svg#icon-chevron-right"></use>
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://fs-job.net/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>エフジョブ（別サイト）</span>
                      <svg className="w-4 md:w-[18px]">
                        <use xlinkHref="/symbol-defs.svg#icon-exlink2"></use>
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.fs-job.net/contact_corporate/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>求人掲載・採用をお考えの方はこちら</span>
                      <svg className="w-4 md:w-[18px]">
                        <use xlinkHref="/symbol-defs.svg#icon-exlink2"></use>
                      </svg>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <button
            onClick={() => transitionToggle(false)}
            className="fixed top-2 right-2 z-30 md:top-4 md:right-14"
          >
            <span className="flex items-center justify-center rounded-full bg-primary w-[72px] aspect-square md:w-20">
              <span className="block">
                <svg className="block w-4 h-4 mt-0.5 mx-auto fill-white">
                  <use xlinkHref="/symbol-defs.svg#icon-delete2"></use>
                </svg>
                <span className="block mt-1.5 text-white text-[10px] font-bold tracking-none leading-none">
                  閉じる
                </span>
              </span>
            </span>
          </button>
        </div>
      )}
    </>
  );
};

export default Header;
